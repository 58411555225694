<template>
  <basic-form-group
    :id="id"
    :label-is-up="labelIsUp"
    :label="$attrs.label"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :small="$attrs.small"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs.alignLabelCenter"
  >
    <template slot="label">
      <slot name="label" />
    </template>
    <template #small>
      <slot name="small" />
    </template>
    <template slot="input">
      <div class="input-group">
        <div
          v-if="$scopedSlots.prepend"
          class="input-group-prepend"
        >
          <slot name="prepend" />
        </div>
        <IntlTelInput
          :id="id"
          ref="inputField"
          v-model="model"
          v-validate="getValidation"
          :class="{'is-invalid': veeFields[veeField] && veeFields[veeField].validated && veeFields[veeField].invalid}"
          :name="veeField"
          :data-vv-as="name"
          v-bind="$attrs"
          class="form-control"
          v-on="listeners"
        >
          <div
            v-if="$scopedSlots.append"
            class="input-group-append"
          >
            <slot name="append" />
          </div>
        </intltelinput>
      </div>
      <validation-feedback :name="veeField" />
    </template>
  </basic-form-group>
</template>
<script>
import basicComponentMixin from './basic-component-mixin.js'
import IntlTelInput from '../IntlTelInput'

export default {
  components: {
    IntlTelInput
  },
  mixins: [basicComponentMixin],
  data () {
    return {
      isVisible: false
    }
  },
  computed: {
    mergeType () {
      if (this.isVisible) return 'text'
      return this.$attrs.type
    },
    isPasswordField () {
      if (this.$attrs.type === 'password') return true
      return false
    }
  }
}
</script>
