<template>
  <input
    ref="phone"
    v-model="number"
    type="tel"
    @input="updateNumber"
    @countrychange="updateNumber"
  >
</template>

<script>
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils.js'
export default {
  data: () => ({
    intl: null,
    number: ''
  }),
  computed: {
    phoneNumber: {
      get () {
        return this.$attrs.value
      },
      set () {
      }
    }
  },
  mounted () {
    const phone = this.$refs.phone
    const system = process.env.VUE_APP_SYSTEM
    const initialCountry = system
    this.intl = intlTelInput(phone, {
      separateDialCode: true,
      preferredCountries: ['de', 'at'],
      initialCountry: initialCountry,
      autoPlaceholder: 'off'
    // any initialisation options go here
    })
    // this.intl.setNumber(this.$attrs.value)
    this.number = this.intl.telInput.value
  },
  destroyed () {
    this.intl.destroy()
  },
  methods: {
    updateNumber () {
      this.$emit('input', this.intl.getNumber())
    }
  }
}
</script>

<style>
@import '~intl-tel-input/build/css/intlTelInput.css';
.iti { width: 100%; }
.intl-tel-input {
  display: table-cell;
}
.intl-tel-input .selected-flag {
  z-index: 4;
}
.intl-tel-input .country-list {
  z-index: 5;
}
.input-group .intl-tel-input .form-control {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
}

</style>
